:root {
  --ion-color-primary: #439CCB;//#428cff;
  --ion-color-primary-rgb: 67, 156, 203;//66,140,255;
  --ion-color-primary-contrast: #112A46;//#ffffff;
  --ion-color-primary-contrast-rgb: 17,42,70;//255,255,255;
  --ion-color-primary-shade: #60b1dd;//#3a7be0;
  --ion-color-primary-tint: #48a8db;//#5598ff;

  --ion-color-secondary: #888842;//#50c8ff;
  --ion-color-secondary-rgb: 136, 136, 66;//80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #c0c07c;//#46b0e0;
  --ion-color-secondary-tint: #666632;//#62ceff;

  --ion-color-tertiary: #83AFB9;//#1d3f75;
  --ion-color-tertiary-rgb: 131, 175, 185;//29, 63, 117;
  --ion-color-tertiary-contrast: #000000;//#ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;//255,255,255;
  --ion-color-tertiary-shade: #a9cbd3;//#12284b;
  --ion-color-tertiary-tint: #7398a0;//#255096;

  --ion-color-success: #95BB4C;//#2fdf75;
  --ion-color-success-rgb: 149, 187, 76;//47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #85ad39;//#29c467;
  --ion-color-success-tint: #52682a;//#44e283;

  --ion-color-warning: #ED6F2D;//#ffd534;
  --ion-color-warning-rgb: 237, 111, 45;//255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #ff9258;//#e0bb2e;
  --ion-color-warning-tint: #8d451d;//#ffd948;

  --ion-color-danger: #5D1362;//#ff4961;
  --ion-color-danger-rgb: 93, 19, 98;//255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #933899;//#e04055;
  --ion-color-danger-tint: #2f0931;//#ff5b71;

  --ion-color-dark: #EEECE2;//#f4f5f8;
  --ion-color-dark-rgb: 238, 236, 226;//244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #ffffff;//#d7d8da;
  --ion-color-dark-tint: #d4d3ca;//#f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #000000;//#222428;
  --ion-color-light-rgb: 0, 0, 0;//34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #181818;//#1e2023;
  --ion-color-light-tint: #252525;//#383a3e;



  --ion-color-camera-button: #206185;
  --ion-color-camera-button-rgb: 32,97,133;
  --ion-color-camera-button-contrast: #cacaca;
  --ion-color-camera-button-contrast-rgb: 202,202,202;
  --ion-color-camera-button-shade: #185a7e;
  --ion-color-camera-button-tint: #27729b;


  --ion-color-phase-none: #181818;
  --ion-color-phase-none-rgb: 24, 24, 24;
  --ion-color-phase-none-contrast: #ffffff;
  --ion-color-phase-none-contrast-rgb: 255,255,255;
  --ion-color-phase-none-shade: #171818;
  --ion-color-phase-none-tint: #202020;
  
  --ion-color-phase-one: #dda809;
  --ion-color-phase-one-rgb: 221,168,9;
  --ion-color-phase-one-contrast: #000000;
  --ion-color-phase-one-contrast-rgb: 0,0,0;
  --ion-color-phase-one-shade: #cc9b08;
  --ion-color-phase-one-tint: #e9b10a;

  --ion-color-phase-two: #439CCB;
  --ion-color-phase-two-rgb: 67,156,203;
  --ion-color-phase-two-contrast: #000000;
  --ion-color-phase-two-contrast-rgb: 0,0,0;
  --ion-color-phase-two-shade: #3f95c4;
  --ion-color-phase-two-tint: #47a3d4;

  --ion-color-phase-three: #377D22;
  --ion-color-phase-three-rgb: 55, 125, 34;
  --ion-color-phase-three-contrast: #ffffff;
  --ion-color-phase-three-contrast-rgb: 255,255,255;
  --ion-color-phase-three-shade: #377D22;
  --ion-color-phase-three-tint: #377D22;

  --ion-color-phase-three-ko: #7d222e;
  --ion-color-phase-three-ko-rgb: 125, 34, 46;
  --ion-color-phase-three-ko-contrast: #ffffff;
  --ion-color-phase-three-ko-contrast-rgb: 255,255,255;
  --ion-color-phase-three-ko-shade: #7d222e;
  --ion-color-phase-three-ko-tint: #7d222e;
}

body {
  --ion-background-color: #242525;
  --ion-background-color-rgb: 36, 37, 37;
  --ion-item-background: #19191a;
  --ion-card-background: #19191a;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;
  --ion-border-color: #5e5e5e;

  --ion-toolbar-background: #FFFFFF;
  --ion-tab-bar-background: #FFFFFF;

  --ion-toolbar-background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(208,208,208,1) 100%);
  --ion-toolbar-background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(208,208,208,1) 100%);
  --ion-toolbar-background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(208,208,208,1) 100%);


  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}

.ion-color-camera-button {
  --ion-color-base: var(--ion-color-camera-button) !important;
  --ion-color-base-rgb: var(--ion-color-camera-button-rgb) !important;
  --ion-color-contrast: var(--ion-color-camera-button-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-camera-button-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-camera-button-shade) !important;
  --ion-color-tint: var(--ion-color-camera-button-tint) !important;
}

.ion-color-phase-none {
  --ion-color-base: var(--ion-color-phase-none) !important;
  --ion-color-base-rgb: var(--ion-color-phase-none-rgb) !important;
  --ion-color-contrast: var(--ion-color-phase-none-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phase-none-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phase-none-shade) !important;
  --ion-color-tint: var(--ion-color-phase-none-tint) !important;
}

.ion-color-phase-one {
  --ion-color-base: var(--ion-color-phase-one) !important;
  --ion-color-base-rgb: var(--ion-color-phase-one-rgb) !important;
  --ion-color-contrast: var(--ion-color-phase-one-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phase-one-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phase-one-shade) !important;
  --ion-color-tint: var(--ion-color-phase-one-tint) !important;
}

.ion-color-phase-two {
  --ion-color-base: var(--ion-color-phase-two) !important;
  --ion-color-base-rgb: var(--ion-color-phase-two-rgb) !important;
  --ion-color-contrast: var(--ion-color-phase-two-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phase-two-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phase-two-shade) !important;
  --ion-color-tint: var(--ion-color-phase-two-tint) !important;
}

.ion-color-phase-three {
  --ion-color-base: var(--ion-color-phase-three) !important;
  --ion-color-base-rgb: var(--ion-color-phase-three-rgb) !important;
  --ion-color-contrast: var(--ion-color-phase-three-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phase-three-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phase-three-shade) !important;
  --ion-color-tint: var(--ion-color-phase-three-tint) !important;
}

.ion-color-phase-three-ko {
  --ion-color-base: var(--ion-color-phase-three-ko) !important;
  --ion-color-base-rgb: var(--ion-color-phase-three-ko-rgb) !important;
  --ion-color-contrast: var(--ion-color-phase-three-ko-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phase-three-ko-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phase-three-ko-shade) !important;
  --ion-color-tint: var(--ion-color-phase-three-ko-tint) !important;
}