/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~leaflet/dist/leaflet.css";

script + div {
    display: inline-flex !important;
    background-color: white !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
  }

img[src="screen"] {
    position: unset !important;
    position: absolute;
    top: unset !important;
    left: unset !important;
    max-width: 100% !important;
    max-height: 100% !important;
    margin: unset !important;
}

.alert-wrapper {
    width: 100% !important;
}

.swiper-wrapper {
    align-items: center;
}

ion-icon.marker {
    width: 100%;
    height: 100%;
}

ion-content::-webkit-scrollbar {
    display: none !important;
}